<template>
    <div class="our-loving-clients ptb-100 bg-color-f8fbfa">
        <div class="container">
            <div class="section-title">
                <h2>Our Loving Clients</h2>
            </div>

            <div class="clients-logo-list align-items-center">
                <div class="single-clients-logo">
                    <a href="#">
                        <img src="../../assets/img/clients-image/1.png" alt="image">
                    </a>
                </div>

                <div class="single-clients-logo">
                    <a href="#">
                        <img src="../../assets/img/clients-image/2.png" alt="image">
                    </a>
                </div>

                <div class="single-clients-logo">
                    <a href="#">
                        <img src="../../assets/img/clients-image/3.png" alt="image">
                    </a>
                </div>

                <div class="single-clients-logo">
                    <a href="#">
                        <img src="../../assets/img/clients-image/4.png" alt="image">
                    </a>
                </div>

                <div class="single-clients-logo">
                    <a href="#">
                        <img src="../../assets/img/clients-image/5.png" alt="image">
                    </a>
                </div>

                <div class="single-clients-logo">
                    <a href="#">
                        <img src="../../assets/img/clients-image/6.png" alt="image">
                    </a>
                </div>

                <div class="single-clients-logo">
                    <a href="#">
                        <img src="../../assets/img/clients-image/7.png" alt="image">
                    </a>
                </div>

                <div class="single-clients-logo">
                    <a href="#">
                        <img src="../../assets/img/clients-image/8.png" alt="image">
                    </a>
                </div>

                <div class="single-clients-logo">
                    <a href="#">
                        <img src="../../assets/img/clients-image/9.png" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurLovingClients'
}
</script>