<template>
    <div class="testimonials-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Whats Our Clients Said About <span>KADTech</span></h2>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-6">
                    <div class="single-testimonials-item">
                        <div class="client-info">
                            <img src="../../assets/img/author-image//6.jpg" alt="image">
                            <h3>Michel John</h3>
                            <span>CEO at Envato</span>
                        </div>

                        <div class="testimonials-desc">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                            <div class="rating">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="single-testimonials-item">
                        <div class="client-info">
                            <img src="../../assets/img/author-image//7.jpg" alt="image">
                            <h3>Sarah Taylor</h3>
                            <span>CEO at HiboTheme</span>
                        </div>

                        <div class="testimonials-desc">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                            <div class="rating">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="single-testimonials-item">
                        <div class="client-info">
                            <img src="../../assets/img/author-image//8.jpg" alt="image">
                            <h3>James Andy</h3>
                            <span>CEO at 3S</span>
                        </div>

                        <div class="testimonials-desc">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                            <div class="rating">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="single-testimonials-item">
                        <div class="client-info">
                            <img src="../../assets/img/author-image//9.jpg" alt="image">
                            <h3>Jisan James</h3>
                            <span>CEO at IT4u</span>
                        </div>

                        <div class="testimonials-desc">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                            <div class="rating">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatsOurClients'
}
</script>