<template>
    <div class="ptb-100 bg-f4f6fc">
        <div class="container">
            <div class="section-title">
                <h2 class="mb-2">More to Discover</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
        </div>

        <div class="overview-item">
            <div class="container max-width-1290">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="overview-left-img">
                            <img src="../../assets/img/home-saas/feature2.png" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="overview-content pl-3">
                            <span class="number">01</span>
                            <h3>Getting Started Page</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                            <ul>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Unique Design
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Unimited Video Call
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Add Favourite contact
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Camera Filter
                                </li>
                            </ul>
                            <a href="#" class="default-btn black-btn">
                                <i class='bx bxs-arrow-to-right'></i>
                                Read More 
                                <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-item">
            <div class="container max-width-1290">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="overview-content pl-3">
                            <span class="number">02</span>
                            <h3>Outdated Comments Toggling</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                            <ul>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Mordan Design
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Unimited Video Call
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Add Favourite contact
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Camera Filter
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Outdated Comments
                                </li>
                            </ul>
                            <a href="#" class="default-btn black-btn">
                                <i class='bx bxs-arrow-to-right'></i>
                                Read More 
                                <span></span>
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="overview-right-img">
                            <img src="../../assets/img/home-saas/feature3.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-item">
            <div class="container max-width-1290">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="overview-left-img">
                            <img src="../../assets/img/home-saas/feature4.png" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="overview-content pl-3">
                            <span class="number">03</span>
                            <h3>Code Review Illustrations</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                            <ul>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Professional Code
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Unimited Video Call
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Add Favourite contact
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Camera Filter
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Start Coding Format
                                </li>
                            </ul>
                            <a href="#" class="default-btn black-btn">
                                <i class='bx bxs-arrow-to-right'></i>
                                Read More 
                                <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MoreToDiscover'
}
</script>