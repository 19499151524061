<template>
    <div class="free-trial-area ptb-100 bg-f4f5fe">
        <div class="container">
            <div class="free-trial-content">
                <h2>We always try to be the best support to you as possible</h2>
                <p>Qualify your leads & recognize the value of word your customer will love you</p>

                <router-link to="/contact" class="default-btn">
                    <i class="bx bxs-hot"></i> 
                    Contact Us
                    <span></span>
                </router-link>
            </div>
        </div>

        <div class="shape10"><img src="../../assets/img/shape/10.png" alt="image"></div>
        <div class="shape11"><img src="../../assets/img/shape/7.png" alt="image"></div>
        <div class="shape12"><img src="../../assets/img/shape/11.png" alt="image"></div>
        <div class="shape13"><img src="../../assets/img/shape/12.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'FreeTrial'
}
</script>