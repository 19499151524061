<template>
    <div>
        <Navbar/>
        <PageTitle 
            pageTitle="Portfolio" 
            pageDesc="our recent works!" 
        />
        <Blog />
        <!-- <OurLovingClients /> -->
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar';
import PageTitle from '../Common/PageTitle';
import Blog from '../blog-one/Blog';
//import OurLovingClients from '../Common/OurLovingClients'
import Footer from '../Layouts/Footer'

export default {
    components: {
        Navbar,
        PageTitle,
        Blog,
        // OurLovingClients,
        Footer,
    }
};
</script>