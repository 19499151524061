<template>
    <div>
        <Navbar />
        <MainBanner />
        <Partner />
        <ConnectionsYourcCustomers />
        <TheBestInnovative />
        <TheAbilityToElicit />
        <WhatsOurClients />
        <OurLovingClientsOne />
        <PricingPlan />
        <ReadyToHelpYou />
        <Faq class="bg-color-f4f5fe" />
        <LatestNews />
        <BestSupport />
        <FreeTrial />
        <FooterStyleOne />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar';
import MainBanner from '../home-six/MainBanner';
import Partner from '../Common/Partner';
import ConnectionsYourcCustomers from '../Common/ConnectionsYourcCustomers';
import TheBestInnovative from '../Common/TheBestInnovative';
import TheAbilityToElicit from '../Common/TheAbilityToElicit';
import WhatsOurClients from '../home-six/WhatsOurClients';
import OurLovingClientsOne from '../Common/OurLovingClientsOne';
import PricingPlan from '../home-six/PricingPlan';
import ReadyToHelpYou from '../Common/ReadyToHelpYou'
import Faq from '../Common/Faq'
import LatestNews from '../Common/LatestNews'
import BestSupport from '../Common/BestSupport'
import FreeTrial from '../Common/FreeTrial';
import FooterStyleOne from '../Layouts/FooterStyleOne'

export default {
    components: {
        Navbar,
        MainBanner,
        Partner,
        ConnectionsYourcCustomers,
        TheBestInnovative,
        TheAbilityToElicit,
        WhatsOurClients,
        OurLovingClientsOne,
        PricingPlan,
        ReadyToHelpYou,
        Faq,
        LatestNews,
        BestSupport,
        FreeTrial,
        FooterStyleOne,
    }
};
</script>
