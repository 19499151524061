<template>
    <div class="about-area">
        <div class="container">
            <div class="section-title text-left">
                <span class="sub-title">About Us</span>
                <h2>We Are A Strategic Digital Marketing Agency</h2>
            </div>
        </div>

        <div class="container-fluid">
            <div class="about-inner-area">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-inner-image">
                            <img src="../../assets/img/marketing-agency/about-image.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="about-inner-content">
                            <div class="content">
                                <h2>We Can Build Strategy That Would Make a Big Difference</h2>
                                <ul class="features-list">
                                    <li><i class='bx bx-check'></i> Complete Marketing Solutions</li>
                                    <li><i class='bx bx-check'></i> Flexible Working Hours</li>
                                    <li><i class='bx bx-check'></i> Experienced Team Members</li>
                                    <li><i class='bx bx-check'></i> Outstanding Digital Experience</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs'
}
</script>