<template>
    <div>
        <NavbarStyleTwo />
        <PageTitle pageTitle="Services Style Two" pageDesc="Our Services" />
        <ServicesOne />
        <WhatsOurClients />
        <WhyChooseUs />
        <PricingPlan />
        <ReadyToHelpYou />
        <BestSupport />
        <OurLovingClients />
        <FooterStyleOne />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layouts/NavbarStyleTwo';
import ServicesOne from '../services-two/ServicesOne'
import PageTitle from '../Common/PageTitle';
import WhatsOurClients from '../services-two/WhatsOurClients'
import WhyChooseUs from '../Common/WhyChooseUs'
import PricingPlan from '../Common/PricingPlan'
import ReadyToHelpYou from '../services-two/ReadyToHelpYou'
import BestSupport from '../Common/BestSupport'
import OurLovingClients from '../Common/OurLovingClients'
import FooterStyleOne from '../Layouts/FooterStyleOne'

export default {
    components: {
        NavbarStyleTwo,
        PageTitle,
        ServicesOne,
        WhatsOurClients,
        WhyChooseUs,
        PricingPlan,
        ReadyToHelpYou,
        BestSupport,
        OurLovingClients,
        FooterStyleOne,
    }
};
</script>
