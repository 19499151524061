<template>
    <div class="team-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Meet Our experts always ready to help you</h2>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="../../assets/img/team-image/1.jpg" alt="image">

                            <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Alex Maxwel</h3>
                            <span>CEO & Founder</span>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="../../assets/img/team-image/2.jpg" alt="image">
                            
                            <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Sarah Taylor</h3>
                            <span>UX/UI Designer</span>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="../../assets/img/team-image/3.jpg" alt="image">
                            
                            <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Lee Munroe</h3>
                            <span>Web Developer</span>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="../../assets/img/team-image/4.jpg" alt="image">
                            
                            <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Calvin Klein</h3>
                            <span>Support</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReadyToHelpYou'
}
</script>