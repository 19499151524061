<template>
    <div class="main-banner chatbot-main-banner">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-7 col-md-12">
                        <div class="main-banner-content">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="content">
                                        <h1>Build your brand connecting with customers</h1>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                        <router-link to="/contact" class="default-btn">
                                            <i class="bx bxs-hot"></i>
                                            Try It Free Now
                                            <span></span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="banner-image bg-2">
                            <div class="chat-wrapper">
                                <div class="chat">
                                    <div class="chat-container">
                                        <div class="chat-listcontainer">
                                            <ul class="chat-message-list">
                                                <li class="message-right ms1">
                                                    <div class="messageinner-ms1">
                                                        <img src="../../assets/img/author-image/6.jpg" alt="Image">
                                                        <span class="message-text">
                                                            👋 Hi! I'm a Striki. Let me know if you have any questions regarding our tool or set up a demo to learn more!
                                                            <span class="message-time">
                                                                19:58
                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                                <li class="message-left ms2">
                                                    <div class="messageinner-ms2">
                                                        <img src="../../assets/img/author-image/7.jpg" alt="Image">
                                                        <span class="message-text">
                                                            I have a Question
                                                            <span class="message-time">
                                                                19:58
                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                                <li class="message-right ms3">
                                                    <div class="messageinner-ms3">
                                                        <img src="../../assets/img/author-image/6.jpg" alt="Image">
                                                        <span class="message-text">
                                                            Sure! Ask me anything!
                                                            <span class="message-time">
                                                                19:58
                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                                <li class="message-left ms4">
                                                    <div class="messageinner-ms4">
                                                        <img src="../../assets/img/author-image/7.jpg" alt="Image">
                                                        <span class="message-text">
                                                            What are you doing?
                                                            <span class="message-time">
                                                                19:58
                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                                <li class="message-right ms5">
                                                    <div class="messageinner-ms5">
                                                        <img src="../../assets/img/author-image/6.jpg" alt="Image">
                                                        <span class="message-text">
                                                            I'm great! I would love to get to better understand how you're doing and your needs, so I can best tell you how I can help you. Let me get some contact details.
                                                            <span class="message-time">
                                                                19:58
                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                                <li class="message-left ms6">
                                                    <div class="messageinner-ms6">
                                                        <img src="../../assets/img/author-image/7.jpg" alt="Image">
                                                        <span class="message-text">
                                                            Could you describe HiboTheme in one sentence?
                                                            <span class="message-time">
                                                                19:58
                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                                <li class="message-right ms7">
                                                    <div class="messageinner-ms7">
                                                        <img src="../../assets/img/author-image/6.jpg" alt="Image">
                                                        <span class="message-text">
                                                            HiboTheme.com | Premium High-Quality Items Design+Dev! Buy all exciting premium templates and themes from HiboTheme!, Thank You!❤️
                                                            <span class="message-time">
                                                                19:58
                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <img src="../../assets/img/banner-img2.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape20"><img src="../../assets/img/shape/19.png" alt="image"></div>
            <div class="shape21"><img src="../../assets/img/shape/20.png" alt="image"></div>
            <div class="shape19"><img src="../../assets/img/shape/18.png" alt="image"></div>
            <div class="shape22"><img src="../../assets/img/shape/21.png" alt="image"></div>
            <div class="shape23"><img src="../../assets/img/shape/22.svg" alt="image"></div>
            <div class="shape24"><img src="../../assets/img/shape/23.png" alt="image"></div>
            <div class="shape26"><img src="../../assets/img/shape/25.png" alt="image"></div>
        </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>