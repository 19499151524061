<template>
    <div>
        <SignUp />
    </div>
</template>

<script>
import SignUp from '../sign-up/SignUp'

export default {
    components: {
        SignUp,
    }
};
</script>