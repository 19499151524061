<template>
  <div class="blog-area ptb-100">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 col-md-6"
          v-for="service in portfolio"
          :key="service.code"
        >
          <div class="single-blog-post portfolio-card">
            <div class="post-image">
              <img :src="service.img" alt="image" />

              <div class="date">
                <i class="bx bx-calendar"></i>
                {{ service.date }}
              </div>
            </div>

            <div class="post-content">
              <h3>
                <a :href="service.link" target="_blank"> {{ service.title }}</a>
              </h3>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-6 col-md-6">
          <div class="single-blog-post portfolio-card">
            <div class="post-image">
              <img
                src="../../assets/img/blog-image/template2.png"
                alt="image"
              />

              <div class="date">
                <i class="bx bx-calendar"></i>
                Jan 14, 2022
              </div>
            </div>

            <div class="post-content">
              <h3>
                <a href="https://somitechsolution.com/spa/#/" target="_blank">
                  Consultancy app/ website sample</a
                >
              </h3>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="single-blog-post portfolio-card">
            <div class="post-image">
             
              <img
                src="../../assets/img/blog-image/template3.png"
                alt="image"
              />
             

              <div class="date">
                <i class="bx bx-calendar"></i>
                March 18, 2022
              </div>
            </div>

            <div class="post-content">
              <h3>
                
                <a href="https://nabconcreteservice.web.app/#/" target="_blank">
                  Construction buiness app/ website sample</a
                >
              </h3>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="single-blog-post portfolio-card">
            <div class="post-image">
              <img
                src="../../assets/img/blog-image/template4.png"
                alt="image"
              />

              <div class="date">
                <i class="bx bx-calendar"></i>
                March 18, 2022
              </div>
            </div>

            <div class="post-content">
              <h3>
                <a
                  href="https://somitech-school.web.app/spa/#/"
                  target="_blank"
                >
                  Construction buiness app/ website sample</a
                >
              </h3>
            </div>
          </div>
        </div> -->

        <div class="col-lg-12 col-md-12">
          <div class="pagination-area">
            <a href="#" class="prev page-numbers"
              ><i class="bx bxs-arrow-to-left"></i
            ></a>

            <span class="page-numbers current" aria-current="page">1</span>
            <!-- <a href="#" class="page-numbers current">1</a>
            <a href="#" class="page-numbers">3</a>
            <a href="#" class="page-numbers">4</a>-->
            <a href="#" class="next page-numbers"
              ><i class="bx bxs-arrow-to-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firebaseDb, ref, onValue } from "../../data/firebase";
export default {
  name: "Blog",
  data() {
    return {
      portfolio: [],
      // portfolio: {
      //   service1: {
      //     code: "template1",
      //     img: "https://firebasestorage.googleapis.com/v0/b/kad-tech.appspot.com/o/template1.png?alt=media&token=a4fe77c6-5cec-4b5b-b47a-dfde09a437b0",
      //     date: "Jan 14, 2022",
      //     title: "Online food order app/ website sample",
      //     link: "https://technext.github.io/feane/menu.html",
      //   },
      //   service2: {
      //     code: "template1",
      //     img: "https://firebasestorage.googleapis.com/v0/b/kad-tech.appspot.com/o/template2.png?alt=media&token=973ecfba-d1fb-44bd-ba75-442d42a7b331",
      //     date: "Jan 14, 2022",
      //     title: "Consultancy app/ website sample",
      //     link: "https://somitechsolution.com/spa/#/",
      //   },
      //   service3: {
      //     code: "template1",
      //     img: "https://firebasestorage.googleapis.com/v0/b/kad-tech.appspot.com/o/template3.png?alt=media&token=577f48e5-92d9-410b-ae14-bfacffb462ad",
      //     date: "March 18, 2022",
      //     title: "Construction buiness app/ website sample",
      //     link: "https://nabconcreteservice.web.app/#/",
      //   },
      //   service4: {
      //     code: "template1",
      //     img: "https://firebasestorage.googleapis.com/v0/b/kad-tech.appspot.com/o/template4.png?alt=media&token=ae86eebc-0c9a-43cf-a0aa-c53de9b4c6ae",
      //     date: "July 10, 2022",
      //     title: "School Website/ website sample",
      //     link: "https://somitech-school.web.app/spa/#/",
      //   },
      // },
    };
  },
  mounted() {
    const dbRef = ref(firebaseDb, "portfolio");
    onValue(
      dbRef,
      (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          // debugger;
          //const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
          // ...
          this.portfolio.push(childData);
        });
      },
      {
        onlyOnce: true,
      }
    );
  },
};
</script>
<style scoped>
.portfolio-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 10px #00000040;
  margin-top: 2rem;
  transition: all 0.4s ease;
}
</style>
