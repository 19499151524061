<template>
    <div>
        <Navbar />
        <MainBanner />
        <Partner />
        <TheAbilityToElicit />
        <ConnectionsYourcCustomers class="bg-f4f6fc" />
        <TheBestInnovative />
        <OurLovingClientsOne />
        <WhatsOurClients />
        <PricingPlan />
        <ReadyToHelpYou />
        <DownloadApp />
        <LatestNews />
        <BestSupport />
        <FreeTrial />
        <FooterStyleOne />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar';
import MainBanner from '../home-seven/MainBanner';
import Partner from '../Common/Partner';
import TheAbilityToElicit from '../Common/TheAbilityToElicit';
import ConnectionsYourcCustomers from '../Common/ConnectionsYourcCustomers';
import TheBestInnovative from '../Common/TheBestInnovative';
import OurLovingClientsOne from '../Common/OurLovingClientsOne';
import WhatsOurClients from '../home-two/WhatsOurClients';
import PricingPlan from '../Common/PricingPlan';
import ReadyToHelpYou from '../Common/ReadyToHelpYou'
import DownloadApp from '../Common/DownloadApp';
import LatestNews from '../Common/LatestNews'
import BestSupport from '../Common/BestSupport'
import FreeTrial from '../Common/FreeTrial';
import FooterStyleOne from '../Layouts/FooterStyleOne'

export default {
    components: {
        Navbar,
        MainBanner,
        Partner,
        TheAbilityToElicit,
        ConnectionsYourcCustomers,
        TheBestInnovative,
        OurLovingClientsOne,
        WhatsOurClients,
        PricingPlan,
        ReadyToHelpYou,
        DownloadApp,
        LatestNews,
        BestSupport,
        FreeTrial,
        FooterStyleOne,
    }
};
</script>
