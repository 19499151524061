<template>
    <div>
        <Navbar />
        <MainBanner />
        <Features />
        <ITConsultancy />
        <HostingServices />
        <ReadyToHelpYou />
        <AmazingFeatures />
        <WhatsOurClients />
        <PricingPlan />
        <OurLovingClients class="bg-white bg-color-f8fbfa" />
        <DownloadApp />
        <LatestNews />
        <BestSupport />
        <FreeTrial />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar';
import MainBanner from '../home-three/MainBanner';
import Features from '../Common/Features';
import ITConsultancy from '../home-three/ITConsultancy';
import HostingServices from '../home-three/HostingServices';
import ReadyToHelpYou from '../Common/ReadyToHelpYou';
import AmazingFeatures from '../home-three/AmazingFeatures';
import WhatsOurClients from '../home-three/WhatsOurClients';
import PricingPlan from '../Common/PricingPlan';
import OurLovingClients from '../Common/OurLovingClients';
import DownloadApp from '../Common/DownloadApp';
import LatestNews from '../Common/LatestNews'
import BestSupport from '../home-three/BestSupport'
import FreeTrial from '../Common/FreeTrial'
import Footer from '../Layouts/Footer'

export default {
    components: {
        Navbar,
        MainBanner,
        Features,
        ITConsultancy,
        HostingServices,
        ReadyToHelpYou,
        AmazingFeatures,
        WhatsOurClients,
        PricingPlan,
        OurLovingClients,
        DownloadApp,
        LatestNews,
        BestSupport,
        FreeTrial,
        Footer,
    }
};
</script>
