<template>
    <div class="lets-talk-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="lets-talk-content">
                        <span class="sub-title">Let’s Talk</span>
                        <h2>Get Started A Project?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="lets-talk-btn">
                        <router-link to="/contact" class="default-btn">
                            <i class="bx bxs-hot"></i>Contact Us<span></span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetStarted'
}
</script>