<template>
    <div class="features-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="features-box">
                        <div class="icon">
                            <i class='bx bx-conversation'></i>
                        </div>
                        <h3>IT Consultancy</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>

                        <div class="back-icon">
                            <i class='bx bx-conversation'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="features-box">
                        <div class="icon">
                            <i class='bx bx-mobile'></i>
                        </div>
                        <h3>Mobile Applications</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>

                        <div class="back-icon">
                            <i class='bx bx-mobile'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="features-box">
                        <div class="icon">
                            <i class='bx bxs-badge-check'></i>
                        </div>
                        <h3>IT Solutions</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>

                        <div class="back-icon">
                            <i class='bx bxs-badge-check'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="features-box">
                        <div class="icon">
                            <i class='bx bx-laptop'></i>
                        </div>
                        <h3>Web Development</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>

                        <div class="back-icon">
                            <i class='bx bx-laptop'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="features-box">
                        <div class="icon">
                            <i class='bx bx-cart'></i>
                        </div>
                        <h3>eCommerce</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>

                        <div class="back-icon">
                            <i class='bx bx-cart'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="features-box">
                        <div class="icon">
                            <i class='bx bxs-dashboard'></i>
                        </div>
                        <h3>Project Management</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>

                        <div class="back-icon">
                            <i class='bx bxs-dashboard'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="features-box">
                        <div class="icon">
                            <i class='bx bx-data'></i>
                        </div>
                        <h3>Web Hosting</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>

                        <div class="back-icon">
                            <i class='bx bxs-bell-ring'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="features-box">
                        <div class="icon">
                            <i class='bx bxs-info-circle'></i>
                        </div>
                        <h3>Technical Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>

                        <div class="back-icon">
                            <i class='bx bxs-info-circle'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="features-box">
                        <div class="icon">
                            <i class='bx bx-cog'></i>
                        </div>
                        <h3>SEO, Affiliate Marketing</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>

                        <div class="back-icon">
                            <i class='bx bx-cog'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesOne'
}
</script>