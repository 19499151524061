<template>
    <div>
        <Navbar />
        <MainBanner />
        <Features />
        <UIDesign />
        <DigitalMarketing />
        <AmazingFeatures />
        <WhatsOurClients />
        <WhyChooseUs />
        <PricingPlan />
        <ReadyToHelpYou />
        <Faq class="bg-color-f4f5fe" />
        <LatestNews />
        <BestSupport />
        <OurLovingClients />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar';
import MainBanner from '../home-two/MainBanner';
import Features from '../Common/Features';
import UIDesign from '../home-two/UIDesign';
import DigitalMarketing from '../home-two/DigitalMarketing';
import AmazingFeatures from '../home-two/AmazingFeatures';
import WhatsOurClients from '../home-two/WhatsOurClients';
import WhyChooseUs from '../Common/WhyChooseUs';
import PricingPlan from '../Common/PricingPlan';
import ReadyToHelpYou from '../home-two/ReadyToHelpYou';
import Faq from '../Common/Faq'
import LatestNews from '../home-two/LatestNews'
import BestSupport from '../Common/BestSupport'
import OurLovingClients from '../Common/OurLovingClients';
import Footer from '../Layouts/Footer'

export default {
    components: {
        Navbar,
        MainBanner,
        Features,
        UIDesign,
        DigitalMarketing,
        AmazingFeatures,
        WhatsOurClients,
        WhyChooseUs,
        PricingPlan,
        ReadyToHelpYou,
        Faq,
        LatestNews,
        BestSupport,
        OurLovingClients,
        Footer,
    }
};
</script>
