<template>
  <div class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
      <div class="section-title">
        <h2>Why Choose KAD Tech Solutions Ltd ?</h2>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-md-6"
          v-for="service in whyChooseUs"
          :key="service.coe"
        >
          <div class="features-box-one">
            <i class="bx" :class="service.icon"></i>
            <h3>{{ service.title }}</h3>
            <p>
              {{ service.description }}
            </p>
          </div>
        </div>

        <!-- <div class="col-lg-4 col-md-6">
          <div class="features-box-one">
            <i class="bx bxs-badge-check bg-6610f2"></i>
            <h3>Experienced Engineers</h3>
            <p>
              We have a team of experienced engineers who work both on our
              helpdesk and on-site to support you with the implementation and
              use of your IT systems.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="features-box-one">
            <i class="bx bxs-dashboard bg-ffb700"></i>
            <h3>Competitive Pricing</h3>
            <p>
              We’re a not-for-profit organisation, so everything you pay is put
              back into improving and developing our services.
            </p>
          </div>
        </div> -->

        <!-- <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-bell-ring bg-fc3549'></i>
                       <h3>Friendly and Flexible Support Team</h3>
                        <p>Our experienced team are from a variety of professional backgrounds. We’re just a phone call or email away whenever you need support.</p>
                     </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-info-circle bg-00d280'></i>
                        <h3>Test</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-cog bg-ff612f'></i>
                        <h3>Flexible Functionality</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { firebaseDb, ref, onValue } from "../../data/firebase";
export default {
  name: "AmazingFeatures",
  data() {
    return {
      whyChooseUs: [],
      // whyChooseUs: {
      //   service1: {
      //     code: "Friendly-and-Flexible",
      //     description:
      //       "Our experienced team are from a variety of professional backgrounds. We’re just a phone call or email away whenever you need support.",
      //     icon: "bxs-bell-ring bg-fc3549",
      //     title: "Friendly and Flexible Support Team",
      //   },
      //   service2: {
      //     code: "Experienced-Engineers",
      //     description:
      //       "We have a team of experienced engineers who work both on our helpdesk and on-site to support you with the implementation and use of your IT systems.",
      //     icon: "bxs-badge-check bg-6610f2",
      //     title: "Experienced Engineers",
      //   },
      //   service3: {
      //     code: "Competitive-Pricing",
      //     description:
      //       "We’re a not-for-profit organisation, so everything you pay is put back into improving and developing our services.",
      //     icon: "bxs-dashboard bg-ffb700",
      //     title: "Competitive Pricing",
      //   },
      // },
    };
  },
  mounted() {
    const dbRef = ref(firebaseDb, "whyChooseUs");
    onValue(
      dbRef,
      (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          // debugger;
          //const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
          // ...
          this.whyChooseUs.push(childData);
        });
      },
      {
        onlyOnce: true,
      }
    );
  },
};
</script>
