<template>
    <div class="main-banner main-banner-one">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="content">
                                    <h1>One place for all your IT requirements....</h1>
                                    <p>
                                    <b>Welcome to KAD Tech Solutions Ltd. We aim to help local buisnesses streamline and 
                                        consolidate all their IT services into one place to help them expand and 
                                        be successful through the use of technology in their everyday transactions.</b>
                                    </p>

                                    <!-- <router-link to="/contact" class="default-btn">
                                        <i class="bx bxs-hot"></i>Get Started<span></span>
                                    </router-link> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-12">
                    <div class="banner-image mbanner-bg-one">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="animate-banner-image">
                                    <!-- <img src="../../assets/img/animate-banner-img1.jpg" alt="image"> -->
                                     <img src="../../assets/img/local.png" alt="image">
                                </div>
                            </div>
                        </div>
                        
                        <!-- <img src="../../assets/img/banner-slider/banner-img1.jpg" class="mbanner-img" alt="image"> -->
                   <img src="../../assets/img/local.png" class="mbanner-img" alt="image">
                                      </div>
                </div>
            </div>
        </div>
    
        <div class="shape20"><img src="../../assets/img/shape/19.png" alt="image"></div>
        <div class="shape21"><img src="../../assets/img/shape/20.png" alt="image"></div>
        <div class="shape19"><img src="../../assets/img/shape/18.png" alt="image"></div>
        <div class="shape22"><img src="../../assets/img/shape/21.png" alt="image"></div>
        <div class="shape23"><img src="../../assets/img/shape/22.svg" alt="image"></div>
        <div class="shape24"><img src="../../assets/img/shape/23.png" alt="image"></div>
        <div class="shape26"><img src="../../assets/img/shape/25.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>