<template>
    <div>
        <NavbarStyleTwo />
        <MainBanner />
        <Featured />
        <AboutUs />
        <OurServices />
        <CaseStudies />
        <Testimonials />
        <Partner />
        <OurTeam />
        <OurLatestBlog />
        <Video />
        <GetStarted />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layouts/NavbarStyleTwo';
import MainBanner from '../home-eight/MainBanner';
import Featured from '../home-eight/Featured';
import AboutUs from '../home-eight/AboutUs';
import OurServices from '../home-eight/OurServices';
import CaseStudies from '../home-eight/CaseStudies';
import Testimonials from '../home-eight/Testimonials';
import Partner from '../home-eight/Partner';
import OurTeam from '../home-eight/OurTeam';
import OurLatestBlog from '../home-eight/OurLatestBlog'
import Video from '../home-eight/Video';
import GetStarted from '../home-eight/GetStarted';
import Footer from '../Layouts/Footer'

export default {
    components: {
        NavbarStyleTwo,
        MainBanner,
        Featured,
        AboutUs,
        OurServices,
        CaseStudies,
        Testimonials,
        Partner,
        OurTeam,
        OurLatestBlog,
        Video,
        GetStarted,
        Footer,
    }
};
</script>
