<template>
    <div class="featured-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-featured-box">
                        <img src="../../assets/img/marketing-agency/featured-img1.jpg" alt="image">
                        <h3>Marketing Campaigns & Content Creation</h3>
                        <a href="#" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                        <a href="#" class="link-btn"></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-featured-box">
                        <img src="../../assets/img/marketing-agency/featured-img2.jpg" alt="image">
                        <h3>LInk Building & Local Search Strategy</h3>
                        <a href="#" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                        <a href="#" class="link-btn"></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-featured-box">
                        <img src="../../assets/img/marketing-agency/featured-img3.jpg" alt="image">
                        <h3>Search Optimization & Paid Advertising</h3>
                        <a href="#" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                        <a href="#" class="link-btn"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Featured'
}
</script>