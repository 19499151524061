<template>
    <div class="services-area ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="services-image">
                    <div class="image">
                        <img src="../../assets/img/home-saas/feature1.png" alt="image">
                    </div>
                </div>

                <div class="services-content it-service-content">
                    <div class="content">
                        <div class="features-inner-content">
                            <div class="features-item">
                                <i class='bx bx-phone-call bg-13c4a1 blt-radius-0'></i>
                                <h3>Free Caliing Service</h3>
                                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                            </div>

                            <div class="features-item">
                                <i class='bx bx-gift bg-6610f2 blt-radius-0'></i>
                                <h3>Daily Free Gift</h3>
                                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                            </div>

                            <div class="features-item">
                                <i class='bx bx-code-alt bg-ff612f blt-radius-0'></i>
                                <h3>QR Code Scaner</h3>
                                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape9">
            <img src="../../assets/img/shape/9.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>