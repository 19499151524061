<template>
    <div>
        <Navbar/>
        <PageTitle 
            pageTitle="Contact Us" 
            pageDesc="We are just one phone call or an email away!" 
        />
        <Contact />
        <!-- <OurLovingClients /> -->
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar';
import PageTitle from '../Common/PageTitle';
import Contact from '../Contact/Contact'
//import OurLovingClients from '../Common/OurLovingClients'
import Footer from '../Layouts/Footer'

export default {
    components: {
        Navbar,
        PageTitle,
        Contact,
        // OurLovingClients,
        Footer,
    }
};
</script>