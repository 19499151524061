<template>
    <div>
        <NavbarStyleOne />
        <MainBanner />
        <AmazingFeatures />
        <Services />
        <MoreToDiscover />
        <WhyChooseUs />
        <YourApplication />
        <WhatsOurClients />
        <PricingPlan />
        <Faq />
        <OurLovingClients />
        <FreeTrial class="pt-0" />
        <FooterStyleOne />
    </div>
</template>

<script>
import NavbarStyleOne from '../Layouts/NavbarStyleOne'
import MainBanner from '../home-four/MainBanner';
import AmazingFeatures from '../home-four/AmazingFeatures';
import Services from '../home-four/Services';
import MoreToDiscover from '../home-four/MoreToDiscover';
import WhyChooseUs from '../Common/WhyChooseUs'
import YourApplication from '../home-four/YourApplication';
import WhatsOurClients from '../Common/WhatsOurClients';
import PricingPlan from '../Common/PricingPlan';
import Faq from '../Common/Faq'
import OurLovingClients from '../Common/OurLovingClients';
import FreeTrial from '../Common/FreeTrial'
import FooterStyleOne from '../Layouts/FooterStyleOne'

export default {
    components: {
        NavbarStyleOne,
        MainBanner,
        AmazingFeatures,
        Services,
        MoreToDiscover,
        WhyChooseUs,
        YourApplication,
        WhatsOurClients,
        PricingPlan,
        Faq,
        OurLovingClients,
        FreeTrial,
        FooterStyleOne,
    }
};
</script>