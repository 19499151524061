<template>
    <div class="features-area pt-100 pb-70 bg-f4f6fc">
        <div class="container max-width-1290">
            <div class="section-title">
                <h2>Our amazing features</h2>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-cog bg-13c4a1'></i>
                        <h3>Fast and Optimized</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-check-shield bg-6610f2'></i>
                        <h3>Full Security</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-timer bg-ffb700'></i>
                        <h3>Time Saving</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-badge-check bg-fc3549'></i>
                        <h3>Easily Manage</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-lock-open bg-00d280'></i>
                        <h3>Quick Access</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-droplet-half bg-ff612f'></i>
                        <h3>Drag and Drop</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AmazingFeatures'
}
</script>