<template>
    <div class="services-area ptb-100">
        <div class="container">
            <div class="section-title text-left">
                <span class="sub-title">Services</span>
                <h2>Let's Check Our Services</h2>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="row m-0">
                            <div class="col-lg-6 col-md-12 p-0">
                                <div class="content">
                                    <h3><router-link to="/single-service">Social Media Marketing</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                    <router-link to="/single-service" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></router-link>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12 p-0">
                                <div class="image bg-1">
                                    <img src="../../assets/img/marketing-agency/services-img1.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="row m-0">
                            <div class="col-lg-6 col-md-12 p-0">
                                <div class="content">
                                    <h3><router-link to="/single-service">SEO Optimization</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                    <router-link to="/single-service" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></router-link>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12 p-0">
                                <div class="image bg-2">
                                    <img src="../../assets/img/marketing-agency/services-img2.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="row m-0">
                            <div class="col-lg-6 col-md-12 p-0">
                                <div class="content">
                                    <h3><router-link to="/single-service">Advanced Analytics</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                    <router-link to="/single-service" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></router-link>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12 p-0">
                                <div class="image bg-3">
                                    <img src="../../assets/img/marketing-agency/services-img3.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="row m-0">
                            <div class="col-lg-6 col-md-12 p-0">
                                <div class="content">
                                    <h3><router-link to="/single-service">Email Marketing</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                    <router-link to="/single-service" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></router-link>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12 p-0">
                                <div class="image bg-4">
                                    <img src="../../assets/img/marketing-agency/services-img4.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="services-btn-box">
                        <router-link to="/services-three" class="default-btn">
                            <i class='bx bx-bullseye'></i>View All<span></span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurServices'
}
</script>