<template>
    <div class="features-area pt-100 pb-70 bg-f4f6fc">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-conversation bg-13c4a1'></i>
                        <h3>IT Consultancy</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-mobile bg-6610f2'></i>
                        <h3>Mobile Applications</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-badge-check bg-ffb700'></i>
                        <h3>IT Solutions</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-laptop bg-9c27b0'></i>
                        <h3>Web Development</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-cart bg-3f51b5'></i>
                        <h3>eCommerce</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-dashboard bg-e91e63'></i>
                        <h3>Project Management</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-data bg-fc3549'></i>
                        <h3>Web Hosting</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-info-circle bg-00d280'></i>
                        <h3>Technical Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-cog bg-ff612f'></i>
                        <h3>SEO, Affiliate Marketing</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                        <router-link to="/single-service" class="read-more">
                            Read more 
                            <i class='bx bx-right-arrow-alt'></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>