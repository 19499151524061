<template>
    <footer class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <!-- <div class="logo">
                            <router-link to="/"><img src="../../assets/img/logo.png" alt="image"></router-link>
                        </div> -->
                        <p>GET SET TO RULE THE EVER-CHANGING BUSINESS WORLD WITH OUR INTELLIGENCE AND COMPETENCE.</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Company</h3>

                        <ul class="services-list">
                            <li><router-link to="/about">About Us</router-link></li>
                            <!-- <li><router-link to="/blog-one">Latest News</router-link></li> -->
                            <!-- <li><router-link to="/services-one">Services</router-link></li>
                            <li><router-link to="/features-one">Features</router-link></li>
                            <li><router-link to="/pricing">Our Pricing</router-link></li>
                            <router-link to="/blog-one">Latest News</router-link></li> 
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Support</h3>

                        <ul class="support-list">
                            <li><router-link to="/faq">FAQ's</router-link></li>
                            <li><router-link to="/#">Privacy Policy</router-link></li>
                            <li><router-link to="/#">Terms & Conditions</router-link></li>
                            <li><router-link to="/about">Community</router-link></li> -->
                            <li><router-link to="/contact">Contact Us</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Contact Info</h3>

                        <ul class="footer-contact-info">
                            <li>Location: <a href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38" target="_blank">25 Ravensdale RdHounslow TW4 7EU UK</a></li>
                            <li>Email: <a href="mailto:kadtech2022@gmail.com">kadtech2022@gmail.com</a></li>
                            <li>Phone: <a href="tel:+321754754">+44 7963 492269</a></li>
                        </ul>
                        <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="copyright-area">
                <p>© All rights reserved by KADTech solutions ltd.</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
}
</script>