<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <span class="sub-title">How we are Founded</span>
                        <h2>Take your business to the next level</h2>
                        <p>In this competitive world, a business requires prolific assistance that has attained dexterity in web and mobile app development that can scale up your business ecosystem with elite solutions.</p>
                        <p>KAD Technologies is an undefeatable source of digital transformation technologies and techniques. Our every step ensures to revolutionize the existing trend with an overwhelming solution for businesses as well as users.</p>
                        <p>We offer business-centric and flexible solutions that are specially designed to cater to all your business requirements keeping room for scalability to adapt to the upcoming future requisites.</p>
                        </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-image">
                        <img src="../../assets/img/about-img.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TakeYourBusiness'
}
</script>