<template>
  <div class="pricing-area pt-100 pb-70 bg-f4f5fe">
    <div class="container">
      <div class="section-title">
        <h2>Choose The Pricing Plan</h2>
      </div>

      <div class="pricing-list-tab">
        <div class="tabs">
          <ul class="tabs__header">
            <li
              class="tabs__header-item"
              v-for="tab in tabs"
              v-on:click="selectTab(tab.id)"
              v-bind:class="{ active: activeTab == tab.id }"
              :key="tab.id"
            >
              <i :class="tab.icon"></i>
              {{ tab.name }}
            </li>
          </ul>
        </div>
        <div class="tabs__container">
          <div class="tabs__list" ref="tabsList">
            <div
              class="tabs__list-tab"
              v-for="tab in tabs"
              v-bind:class="{ active: activeTab == tab.id }"
              :key="tab.id"
            >
              <div class="row justify-content-center">
                <div
                  class="col-lg-4 col-md-6"
                  v-for="item in tab.items"
                  :key="item.id"
                >
                  <div class="single-pricing-table left-align">
                    <div class="pricing-header">
                      <h3>{{ item.title }}</h3>
                    </div>

                    <div class="price">
                      <sup>£</sup>{{ item.price }}<sub>/ {{ item.period }}</sub>
                    </div>

                    <ul class="pricing-features">
                      <li
                        v-for="feature in item.pricingFeatures"
                        :key="feature.id"
                      >
                        <i :class="feature.icon"></i>
                        {{ feature.feature }}
                      </li>
                    </ul>

                    <div class="btn-box">
                      <router-link :to="item.btnLink" class="default-btn">
                        <i class="bx bxs-hot"></i>
                        {{ item.btnText }}
                        <span></span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingPlan",
  data() {
    return {
      activeTab: 1,
      offsetRight: 0,
      tabs: [
        {
          id: 1,
          icon: "bx bxs-calendar-check",
          name: "Websites",
          items: [
            {
              id: 1,
              title: "CMS websites",
              price: "20",
              period: "monthly",
              btnText: "Contact us for a deal",
              btnLink: "/contact",
              pricingFeatures: [
                {
                  id: 0,
                  icon: "bx bxs-badge-check",
                  feature: "Hosting included",
                  // feature: 'Customer can choose form our existing ready made templates',
                },
                {
                  id: 1,
                  icon: "bx bxs-badge-check",
                  // feature: 'Hosting included',
                  feature:
                    "Customer can choose form our existing ready made templates",
                },
                {
                  id: 2,
                  icon: "bx bxs-badge-check",
                  feature: "Call and email support in case of downtime",
                },
                {
                  id: 3,
                  icon: "bx bxs-badge-check",
                  feature: "One time cost £200",
                },
                {
                  id: 4,
                  icon: "bx bxs-badge-check",
                  feature: "One time content update",
                },
                {
                  id: 5,
                  icon: "bx bxs-badge-check",
                  feature:
                    "Prices for customosation will be separte and are to be shared via quotes after mutual discussion",
                },
                // {
                //     id: 6,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Info',
                // },
                // {
                //     id: 7,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Mobile + Desktop Apps',
                // },
                // {
                //     id: 8,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Quick Responses',
                // },
                // {
                //     id: 9,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Drag & Drop Widgets',
                // },
                // {
                //     id: 10,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Notes',
                // },
                // {
                //     id: 11,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Google Analytics',
                // },
              ],
            },
            {
              id: 2,
              title: "Customised Websites",
              price: "50",
              period: "monthly",
              btnText: "Contact us for a deal",
              btnLink: "/contact",
              pricingFeatures: [
                {
                  id: 0,
                  icon: "bx bxs-badge-check",
                  feature: "Hosting included",
                },
                {
                  id: 1,
                  icon: "bx bxs-badge-check",
                  // feature: 'Hosting included',
                  feature:
                    "Customer can choose form our existing ready made templates",
                },
                {
                  id: 2,
                  icon: "bx bxs-badge-check",
                  feature: "Call and email support in case of downtime",
                },
                {
                  id: 3,
                  icon: "bx bxs-badge-check",
                  feature: "Includes web and mobile applications",
                },
                {
                  id: 4,
                  icon: "bx bxs-badge-check",
                  feature:
                    "Prices for customosation will be separte and are to be shared via quotes after mutual discussion",
                },
                // {
                //     id: 5,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Messenger Integration',
                // },
                // {
                //     id: 6,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Info',
                // },
                // {
                //     id: 7,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Mobile + Desktop Apps',
                // },
                // {
                //     id: 8,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Quick Responses',
                // },
                // {
                //     id: 9,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Drag & Drop Widgets',
                // },
                // {
                //     id: 10,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Notes',
                // },
                // {
                //     id: 11,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Google Analytics',
                // },
              ],
            },
            {
              id: 3,
              title: "Web applications with third party API Integration",
              price: "80",
              period: "monthly",
              btnText: "Contact us for a deal",
              btnLink: "/contact",
              pricingFeatures: [
                {
                  id: 0,
                  icon: "bx bxs-badge-check",
                  feature: "Hosting included",
                },
                {
                  id: 1,
                  icon: "bx bxs-badge-check",
                  // feature: 'Hosting included',
                  feature:
                    "Customer can choose form our existing ready made templates",
                },
                {
                  id: 2,
                  icon: "bx bxs-badge-check",
                  feature: "Call and email support in case of downtime",
                },
                // {
                //     id: 3,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'One time content update',
                // },
                {
                  id: 4,
                  icon: "bx bxs-badge-check",
                  feature:
                    "Prices for customosation will be separte and are to be shared via quotes after mutual discussion",
                },
                // {
                //     id: 5,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Messenger Integration',
                // },
                // {
                //     id: 6,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Info',
                // },
                // {
                //     id: 7,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Mobile + Desktop Apps',
                // },
                // {
                //     id: 8,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Quick Responses',
                // },
                // {
                //     id: 9,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Drag & Drop Widgets',
                // },
                // {
                //     id: 10,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Notes',
                // },
                // {
                //     id: 11,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Google Analytics',
                // },
              ],
            },
          ],
        },
        {
          id: 2,
          icon: "bx bxs-calendar-check",
          name: "Mobile Apps",
          items: [
            {
              id: 1,
              title: "Static one time app",
              price: "100",
              period: "one time cost",
              btnText: "Contact us for a deal",
              btnLink: "/contact",
              pricingFeatures: [
                {
                  id: 1,
                  icon: "bx bxs-badge-check",
                  feature: "Inculdes one time publishing over the app store",
                },
                {
                  id: 2,
                  icon: "bx bxs-badge-check",
                  feature: "Users can opt in / opt out for adds",
                },
                // {
                //     id: 3,
                //     icon: 'bx bxs-badge-check',
                //     feature: '24/7 Live Chat',
                // },
                // {
                //     id: 4,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Email Integration',
                // },
                {
                  id: 5,
                  icon: "bx bxs-badge-check",
                  feature: "Social media apps Integration",
                },
                // {
                //     id: 6,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Info',
                // },
                // {
                //     id: 7,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Mobile + Desktop Apps',
                // },
                {
                  id: 8,
                  icon: "bx bxs-badge-check",
                  feature: "Quick Responses",
                },
                // {
                //     id: 9,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Drag & Drop Widgets',
                // },
                // {
                //     id: 10,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Notes',
                // },
                {
                  id: 11,
                  icon: "bx bxs-badge-check",
                  feature:
                    "Users can choose from andoid, ios and windows platfrom",
                },
              ],
            },
            {
              id: 2,
              title: "Dynamic web and mobile app",
              price: "25",
              period: "Montly",
              btnText: "Contact us for a deal",
              btnLink: "/contact",
              pricingFeatures: [
                {
                  id: 1,
                  icon: "bx bxs-badge-check",
                  feature: "Inculdes one time publishing over the app store",
                },
                {
                  id: 2,
                  icon: "bx bxs-badge-check",
                  feature: "Includes Mobile + Desktop Apps with hosting",
                },
                // {
                //     id: 3,
                //     icon: 'bx bxs-badge-check',
                //     feature: '24/7 Live Chat',
                // },
                // {
                //     id: 4,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Email Integration',
                // },
                // {
                //     id: 5,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Messenger Integration',
                // },
                // {
                //     id: 6,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Info',
                // },
                // {
                //     id: 7,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Mobile + Desktop Apps',
                // },
                {
                  id: 8,
                  icon: "bx bxs-badge-check",
                  feature: "Quick Responses",
                },
                {
                  id: 9,
                  icon: "bx bxs-badge-check",
                  feature: "Social media apps Integration",
                },
                // {
                //     id: 10,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Notes',
                // },
                {
                  id: 11,
                  icon: "bx bxs-badge-check",
                  feature:
                    "Users can choose from andoid, ios and windows platfrom",
                },
              ],
            },
            {
              id: 3,
              title: "Other utility apps",
              price: "50",
              period: "Montly(average)",
              btnText: "Contact us for a deal",
              btnLink: "/contact",
              pricingFeatures: [
                {
                  id: 0,
                  icon: "bx bxs-badge-check",
                  feature: "Inculdes publishing and one update once a month",
                },
                {
                  id: 1,
                  icon: "bx bxs-badge-check",
                  feature: "Prices are to be shared via quotes",
                },
                // {
                //     id: 2,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Quick Responses',
                // },
                {
                  id: 3,
                  icon: "bx bxs-badge-check",
                  feature: "Social media apps Integration",
                },
                {
                  id: 4,
                  icon: "bx bxs-badge-check",
                  feature:
                    "Users can choose from andoid, ios and windows platfrom",
                },
                {
                  id: 5,
                  icon: "bx bxs-badge-check",
                  feature: "Includes Mobile + Desktop Apps with hosting",
                },
                // {
                //     id: 6,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Info',
                // },
                // {
                //     id: 7,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Mobile + Desktop Apps',
                // },
                // {
                //     id: 8,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Quick Responses',
                // },
                // {
                //     id: 9,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Drag & Drop Widgets',
                // },
                // {
                //     id: 10,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Visitor Notes',
                // },
                // {
                //     id: 11,
                //     icon: 'bx bxs-badge-check',
                //     feature: 'Google Analytics',
                // },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    selectTab(id) {
      let tabsList = this.$refs.tabsList;
      this.activeTab = id;
      this.offsetRight = tabsList.clientWidth * (id - 1);
      tabsList.style.right = this.offsetRight + "px";
    },
  },
  mounted() {
    let tabsList = this.$refs.tabsList;
    tabsList.style.right = this.offsetRight + "px";
  },
};
</script>
