<template>
    <div>
        <Navbar />
        <MainBanner />
        <Features />
         <!-- <MobileDevelopment />  -->
        <!-- <HostingServices />  -->
        <!-- <WhyChooseUs /> -->
        <AmazingFeatures /> 
        <!-- <WhatsOurClients />  -->
        <PricingPlan />
        <!-- <ReadyToHelpYou /> -->
        <!-- <OurLovingClients />  -->
        <!-- <Faq /> -->
        <!-- <BestSupport /> -->
        <!-- <LatestNews /> -->
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar';
import MainBanner from '../home-one/MainBanner';
import Features from '../Common/Features';
// import MobileDevelopment from '../home-one/MobileDevelopment';
// import HostingServices from '../home-one/HostingServices';
//import WhyChooseUs from '../Common/WhyChooseUs';
import AmazingFeatures from '../home-one/AmazingFeatures';
//import WhatsOurClients from '../Common/WhatsOurClients';
import PricingPlan from '../Common/PricingPlan';
//import ReadyToHelpYou from '../Common/ReadyToHelpYou';
//import OurLovingClients from '../Common/OurLovingClients';
//import Faq from '../Common/Faq'
//import BestSupport from '../home-one/BestSupport'
//import LatestNews from '../Common/LatestNews'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomePageOne',
    components: {
        Navbar,
        MainBanner,
        Features,
        // MobileDevelopment,
        // HostingServices,
       // WhyChooseUs,
        AmazingFeatures,
      //  WhatsOurClients,
        PricingPlan,
       // ReadyToHelpYou,
      // OurLovingClients,
       // Faq,
      //  BestSupport,
      //  LatestNews,
        Footer,
    }
};
</script>
