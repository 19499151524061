import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set, push, onValue } from "firebase/database";
//import { getFirestore } from "firebase/firestore";
var firebaseConfig = {
  apiKey: "AIzaSyAvfwzqafbeHWZ4_unI-zsQ6xMqupigNdw",
  authDomain: "kad-tech.firebaseapp.com",
  projectId: "kad-tech",
  storageBucket: "kad-tech.appspot.com",
  messagingSenderId: "340059343860",
  appId: "1:340059343860:web:70d62938939427248407a1",
  measurementId: "G-EWTCN6Y546",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const firebaseAuth = getAuth(app);
const firebaseDb = getDatabase();
export {
  firebaseAuth,
  createUserWithEmailAndPassword,
  firebaseDb,
  ref,
  set,
  push,
  onValue,
};
