<template>
    <div class="features-area pt-100 pb-70 bg-f4f6fc">
        <div class="container">
            <div class="section-title">
                <h2>Our Amazing Features</h2>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class='bx bxs-badge-check'></i>
                        </div>
                        <h3>IT Solutions</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class='bx bx-cog'></i>
                        </div>
                        <h3>Flexible Functionality</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class='bx bx-conversation'></i>
                        </div>
                        <h3>IT Consultancy</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class='bx bxs-dashboard'></i>
                        </div>
                        <h3>Simple Dashboard</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class='bx bxs-info-circle'></i>
                        </div>
                        <h3>Information Retrieval</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class='bx bxs-bell-ring'></i>
                        </div>
                        <h3>Deadline Reminders</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AmazingFeatures'
}
</script>