<template>
    <div class="team-area-two pt-100 pb-70">
        <div class="container">
            <div class="section-title text-left">
                <span class="sub-title">Our Team</span>
                <h2>We Help to Acheive Your Business Goal</h2>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team-member">
                        <div class="image">
                            <img src="../../assets/img/team-image/9.jpg" alt="team-image">

                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>James Anderson</h3>
                            <span>CEO & Founder</span>
                            <i class='bx bx-share-alt'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team-member">
                        <div class="image">
                            <img src="../../assets/img/team-image/10.jpg" alt="team-image">

                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Sarah Taylor</h3>
                            <span>Marketing Lead</span>
                            <i class='bx bx-share-alt'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team-member">
                        <div class="image">
                            <img src="../../assets/img/team-image/11.jpg" alt="team-image">

                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Taylor Sopia</h3>
                            <span>Web Designer</span>
                            <i class='bx bx-share-alt'></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team-member">
                        <div class="image">
                            <img src="../../assets/img/team-image/12.jpg" alt="team-image">

                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Harry Steve</h3>
                            <span>Web Developer</span>
                            <i class='bx bx-share-alt'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurTeam'
}
</script>