<template>
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/img/blog-image/1.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class='bx bx-calendar'></i> 
                                        Oct 14, 2022
                                    </div>
                                </div>

                                <div class="post-content">
                                    <h3>
                                        <router-link to="/single-blog">
                                            50 world-changing people -- We lost in the 2010s
                                        </router-link>
                                    </h3>

                                    <div class="post-info">
                                        <div class="post-by">
                                            <img src="../../assets/img/author-image//1.jpg" alt="image">

                                            <h6>Sarah Taylor</h6>
                                        </div>

                                        <div class="details-btn">
                                            <router-link to="/single-blog">
                                                <i class="bx bx-right-arrow-alt"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/img/blog-image/2.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class='bx bx-calendar'></i> 
                                        Oct 16, 2022
                                    </div>
                                </div>

                                <div class="post-content">
                                    <h3>
                                        <router-link to="/single-blog">
                                            Don't buy a tech gift until you read these rules
                                        </router-link>
                                    </h3>

                                    <div class="post-info">
                                        <div class="post-by">
                                            <img src="../../assets/img/author-image//2.jpg" alt="image">

                                            <h6>Michel John</h6>
                                        </div>

                                        <div class="details-btn">
                                            <router-link to="/single-blog">
                                                <i class="bx bx-right-arrow-alt"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/img/blog-image/3.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class='bx bx-calendar'></i> 
                                        Oct 18, 2022
                                    </div>
                                </div>

                                <div class="post-content">
                                    <h3>
                                        <router-link to="/single-blog">
                                            The golden rule of buying a phone as a gift
                                        </router-link>
                                    </h3>

                                    <div class="post-info">
                                        <div class="post-by">
                                            <img src="../../assets/img/author-image//3.jpg" alt="image">

                                            <h6>Lucy Eva</h6>
                                        </div>

                                        <div class="details-btn">
                                            <router-link to="/single-blog">
                                                <i class="bx bx-right-arrow-alt"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/img/blog-image/4.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class='bx bx-calendar'></i> 
                                        Oct 14, 2022
                                    </div>
                                </div>

                                <div class="post-content">
                                    <h3>
                                        <router-link to="/single-blog">
                                            Configure Redux Into Your WordPress Theme
                                        </router-link>
                                    </h3>

                                    <div class="post-info">
                                        <div class="post-by">
                                            <img src="../../assets/img/author-image//2.jpg" alt="image">

                                            <h6>Sarah Taylor</h6>
                                        </div>

                                        <div class="details-btn">
                                            <router-link to="/single-blog">
                                                <i class="bx bx-right-arrow-alt"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/img/blog-image/5.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class='bx bx-calendar'></i> 
                                        Oct 16, 2022
                                    </div>
                                </div>

                                <div class="post-content">
                                    <h3>
                                        <router-link to="/single-blog">
                                            How To Setup Redux In React Next Application
                                        </router-link>
                                    </h3>

                                    <div class="post-info">
                                        <div class="post-by">
                                            <img src="../../assets/img/author-image//3.jpg" alt="image">

                                            <h6>Michel John</h6>
                                        </div>

                                        <div class="details-btn">
                                            <router-link to="/single-blog">
                                                <i class="bx bx-right-arrow-alt"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/img/blog-image/6.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class='bx bx-calendar'></i> 
                                        Oct 18, 2022
                                    </div>
                                </div>

                                <div class="post-content">
                                    <h3>
                                        <router-link to="/single-blog">
                                            How To Resubmit Rejected Item Into ThemeForest?
                                        </router-link>
                                    </h3>

                                    <div class="post-info">
                                        <div class="post-by">
                                            <img src="../../assets/img/author-image//1.jpg" alt="image">

                                            <h6>Lucy Eva</h6>
                                        </div>

                                        <div class="details-btn">
                                            <router-link to="/single-blog">
                                                <i class="bx bx-right-arrow-alt"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/img/blog-image/7.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class='bx bx-calendar'></i> 
                                        Oct 14, 2022
                                    </div>
                                </div>

                                <div class="post-content">
                                    <h3>
                                        <router-link to="/single-blog">
                                            How To The Active Menu Based On URL In Next.JS?
                                        </router-link>
                                    </h3>

                                    <div class="post-info">
                                        <div class="post-by">
                                            <img src="../../assets/img/author-image//3.jpg" alt="image">

                                            <h6>Sarah Taylor</h6>
                                        </div>

                                        <div class="details-btn">
                                            <router-link to="/single-blog">
                                                <i class="bx bx-right-arrow-alt"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/img/blog-image/8.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class='bx bx-calendar'></i> 
                                        Oct 16, 2022
                                    </div>
                                </div>

                                <div class="post-content">
                                    <h3>
                                        <router-link to="/single-blog">
                                            How To Create A Responsive Popup Gallery?
                                        </router-link>
                                    </h3>

                                    <div class="post-info">
                                        <div class="post-by">
                                            <img src="../../assets/img/author-image//2.jpg" alt="image">

                                            <h6>Michel John</h6>
                                        </div>

                                        <div class="details-btn">
                                            <router-link to="/single-blog">
                                                <i class="bx bx-right-arrow-alt"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="pagination-area">
                                <a href="#" class="prev page-numbers"><i class='bx bxs-arrow-to-left'></i></a>
                                <a href="#" class="page-numbers">1</a>
                                <span class="page-numbers current" aria-current="page">2</span>
                                <a href="#" class="page-numbers">3</a>
                                <a href="#" class="page-numbers">4</a>
                                <a href="#" class="next page-numbers"><i class='bx bxs-arrow-to-right'></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <BlogSidebar />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BlogSidebar from '../Common/BlogSidebar'

export default {
    name: 'BlogOne',
    components: {
        BlogSidebar
    }
}
</script>