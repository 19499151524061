<template>
    <div class="services-area bg-left-color bg-f4f6fc ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="services-image">
                    <div class="image">
                        <img src="../../assets/img/services-image/2.png" alt="image">
                    </div>
                </div>

                <div class="services-content">
                    <div class="content">
                        <div class="icon">
                            <img src="../../assets/img/icon1.png" alt="image">
                        </div>
                        <h2>The best Innovative Chatbot and automations are here to expand</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <router-link to="/single-service" class="default-btn">
                            <i class="bx bxs-spreadsheet"></i> 
                            Learn More 
                            <span></span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheBestInnovative'
}
</script>