<template>
    <div class="app-download-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="app-download-image">
                        <img src="../../assets/img/mobile.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="app-download-content">
                        <span class="sub-title">Download App</span>
                        <h2>Supporting your customers on the go with our mobile app</h2>

                        <div class="btn-box">
                            <a href="https://www.apple.com/" class="apple-store-btn">
                                <img src="../../assets/img/applestore.png" alt="image">
                                Download on the
                                <span>Apple Store</span>
                            </a>

                            <a href="https://play.google.com/store/apps" class="play-store-btn">
                                <img src="../../assets/img/playstore.png" alt="image">
                                Get it on
                                <span>Google Play</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DownloadApp'
}
</script>