<template>
  <div class="features-card-section pt-100 pb-70 bg-f8fbfa">
    <div class="container">
      <div class="section-title">
        <h2>Services</h2>
      </div>
      <div class="row">
        <div
          class="col-lg-3 col-sm-6"
          v-for="service in Services"
          :key="service.code"
        >
          <div class="single-features-card">
            <i class="bx" :class="service.icon"></i>
            <h3>
              <router-link :to="service.link">{{ service.title }}</router-link>
            </h3>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firebaseDb, ref, onValue } from "../../data/firebase";
export default {
  name: "Features",
  data() {
    return {
      Services: [
        // {
        //   code: "MobileAppDevelopment",
        //   description:
        //     "We develop cross-platform mobile apps, for both iOS and Android platforms, that are both sustainable and scalable. We also provide publishing and upgrade service of mobile apps on app store.",
        //   icon: "bx-conversation",
        //   title: "Mobile App Development",
        //   link: "/single-service",
        // },
        // {
        //   code: "WebDevelopment",
        //   description:
        //     "We have an expert team of Web developers with good experience in developing innovative progressive web applications which are fast, reliable, and secure using using latest technology stack. We provide full-stack web development using both front end and backend technologies.",
        //   icon: "bx-laptop",
        //   title: "Web Development",
        //   link: "/single-service",
        // },
        // {
        //   code: "ThirdPartyAPIIntegration",
        //   description:
        //     "Our web development team specializes in developing APIs as per the requirement of our clients. We create API for custom data connection, JSON web services, and middleware for secure connection with third-party solution.",
        //   icon: "bxs-badge-check",
        //   title: "Third Party API Integration",
        //   link: "/single-service",
        // },
        // {
        //   code: "UpgradationMigration",
        //   description:
        //     "If you're looking to upgrade your old website or migrate your existing technology to a new platform than contact us.",
        //   icon: "bxs-badge-check",
        //   title: "Upgradation &amp; Migration",
        //   link: "/single-service",
        // },
      ],
    };
  },
  mounted() {
    const dbRef = ref(firebaseDb, "services");
    onValue(
      dbRef,
      (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          // debugger;
          //const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
          // ...
          this.Services.push(childData);
        });
      },
      {
        onlyOnce: true,
      }
    );
  },
};
</script>
