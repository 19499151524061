<template>
    <div class="digital-agency-banner">
        <div class="container">
            <div class="digital-agency-banner-content">
                <h1>World Leading <span>Digital Marketing</span> Agency</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <router-link to="/contact" class="default-btn">
                    <i class="bx bxs-hot"></i>Get Started Now<span></span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>