<template>
    <div>
        <Navbar />
        <MainBanner />
        <Partner />
        <ConnectionsYourcCustomers />
        <TheBestInnovative />
        <WhyChooseUs />
        <TheAbilityToElicit />
        <WhatsOurClients />
        <PricingPlan />
        <Faq />
        <OurLovingClients />
        <FreeTrial class="pt-0" />
        <FooterStyleOne />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar';
import MainBanner from '../home-five/MainBanner';
import Partner from '../Common/Partner';
import ConnectionsYourcCustomers from '../Common/ConnectionsYourcCustomers';
import TheBestInnovative from '../Common/TheBestInnovative';
import WhyChooseUs from '../Common/WhyChooseUs';
import TheAbilityToElicit from '../home-five/TheAbilityToElicit';
import WhatsOurClients from '../Common/WhatsOurClients';
import PricingPlan from '../Common/PricingPlan';
import Faq from '../Common/Faq'
import OurLovingClients from '../Common/OurLovingClients';
import FreeTrial from '../Common/FreeTrial';
import FooterStyleOne from '../Layouts/FooterStyleOne'

export default {
    components: {
        Navbar,
        MainBanner,
        Partner,
        ConnectionsYourcCustomers,
        TheBestInnovative,
        WhyChooseUs,
        TheAbilityToElicit,
        WhatsOurClients,
        PricingPlan,
        Faq,
        OurLovingClients,
        FreeTrial,
        FooterStyleOne,
    }
};
</script>
