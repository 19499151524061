<template>
    <div class="services-area ptb-100 bg-f4f6fc">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="services-image">
                    <div class="image">
                        <img src="../../assets/img/home-saas/feature5.png" alt="image">
                    </div>
                </div>

                <div class="services-content it-service-content">
                    <div class="content">
                        <div class="fun-facts-inner-content">
                            <h2>Build Beautiful Interface Into Your Application</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                            <ul>
                                <li>
                                    <i class="bx bx-badge-check"></i>
                                    Professional Code
                                </li>
                                <li>
                                    <i class="bx bx-badge-check"></i>
                                    Unimited Video Call
                                </li>
                                <li>
                                    <i class="bx bx-badge-check"></i>
                                    Add Favourite contact
                                </li>
                                <li>
                                    <i class="bx bx-badge-check"></i>
                                    Camera Filter
                                </li>
                                <li>
                                    <i class="bx bx-badge-check"></i>
                                    Start Coding Format
                                </li>
                            </ul>

                            <a href="#" class="default-btn black-btn">
                                <i class="bx bxs-arrow-to-right"></i>
                                Read More 
                                <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape9">
            <img src="../../assets/img/shape/9.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'YourApplication'
}
</script>