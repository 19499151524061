<template>
    <div>
        <Login />
    </div>
</template>

<script>
import Login from '../log-in/Login'

export default {
    components: {
        Login,
    }
};
</script>