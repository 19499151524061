<template>
    <div class="partner-area-two ptb-70 bg-f9f9f9">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                    <div class="single-partner-box">
                        <img src="../../assets/img/partner-image/img1.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                    <div class="single-partner-box">
                        <img src="../../assets/img/partner-image/img2.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                    <div class="single-partner-box">
                        <img src="../../assets/img/partner-image/img3.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                    <div class="single-partner-box">
                        <img src="../../assets/img/partner-image/img4.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                    <div class="single-partner-box">
                        <img src="../../assets/img/partner-image/img5.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                    <div class="single-partner-box">
                        <img src="../../assets/img/partner-image/img6.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Partner'
}
</script>